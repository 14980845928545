import { useAtomValue } from "jotai";
import { localeAtom } from "../../utils/helpers/store";
import { useEffect } from "react";

export default function Storifyme({ className, widgetID }) {
	//const local = useAtomValue(localeAtom);

	useEffect(() => {
		const script = document.createElement("script");

		script.src =
			"https://cdn.storifyme.com/static/web-components/storifyme-elements.min.js";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	if (!widgetID) {
		return <></>;
	}

	return (
		<storifyme-collection
			account="g-matthias-riverti-38614"
			widget={widgetID}
			env="eu"
			className={className}
		/>
	);
}
